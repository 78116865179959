  import React from 'react';
  import './styles.css';
  import ExpandableSection from './ExpandableSection.js';
  import ContentCreation from './ContentCreation.js';
  import ImageGrid from './LBProjectOverview.js';
  import VideoGrid from './VideoGrid.js';
  import ContentCreationExpanded from './ContentCreationExpanded.js';
  import DocumentationWritingExpanded from './DocumentationWritingExpanded.js';
  import WellnessApp from './WellnessApp.js';
  import logo from './logo.png';
  import { Link } from 'react-router-dom';



  const Home = () =>  {
    return (
      <div className="App">
        <header>
          <section>
          </section>
          <nav className="navbar">
            <Link to="/">
              {/* <img src={logo} className="logo" */}

            </Link>
            <ul>
              <li>
                <button className="contact-button" onClick={() => window.location.assign('mailto:cherietanzj@gmail.com')}>Contact</button>
              </li>
            </ul>
          </nav>
        </header>
        <main>
          <section>
            
            <h1 className="landing-page-header-text">Cherie Tan</h1>
            <p>Hi, I'm Cherie. I prefer to let my work do the talking. Here’s a little info. I am a Learning Designer and Creative Technologist who creates in many mediums, from 3d to electronics, 
            visuals, music, and writing. My work has been used in the Australian education industry as well as in a web series. I am passionate about music, art, web and app development, story-telling, electronics and 3d printing.
            </p>
            <ExpandableSection />
          </section>
          <section>
            <h2>Work</h2>
            <h3>Learning Designer</h3>
            <p>My passion for design and technology started in University when I first encountered electronics and 3d printing. In my work, I have assisted in the community management where I moderated online forums on Discourse that ran in 2020; facilitated discussions about all things geeky, coding, 3D modelling and printing, art, design, and gaming. 
            In 2021, we were sponsored by Microsoft to run a web series. My work involved course mapping, designing course content that aligned with learning outcomes, creating and developing multimedia content, and being a speaker in our video podcast. 
            I created the workshop content which includes over 50 workshops on general coding, Blender, AI, UI/UX, game development, art, and web design. The workshops ran from 2020 - 2022, with a full class every Monday.</p>
            <ContentCreationExpanded />
            <ContentCreation />
            <h3>Creative Technologist & Maker</h3>
            <p>Created Little Bird’s online instructional content which includes over 50 guides on the Raspberry Pi, 60 guides on Micro:bit, 18 guides on E-textiles, and 40 guides on Arduino related content. This content has assisted Little Bird customers and schools in topics ranging from sensors, actuators and other hardware, robotics, e-textiles and wearables. The Little Bird Micro:bit Project Kits have also been approved by the NSW Creative Kids program. This is a government funded program that helps school-aged kids (4.5 to 18 years) to get involved in creative and cultural activities. Also created a set of 15 instructional guides for game programming on MakeCode Arcade. The content has been linked to from Adafruit Industries and gained recognition from the developers of MakeCode Arcade. In addition, I have also provided helpdesk support to customers and educators, which has helped the company in gaining over 2,000 Google Reviews and Product Reviews combined with an overall score of 5.0 stars.</p>
            <DocumentationWritingExpanded />
            <ImageGrid />
            <h2>Other Projects</h2>
            <h3>App Design</h3>
            <p>Co-developed SheHacksSwift (2024–25) with Girl Geek Academy, Apple, and RMIT to mentor high school girls in Swift programming. This initiative culminated in an MVP app challenge aimed at addressing community needs while preparing students for the Apple Swift Student Challenge.</p>
            <p>As a creative technologist and learning designer with a deep passion for education and health, I am interested in creating tools that empower people to grow and thrive. In 2025, I started creating a mental health app in Swift featuring CBT, gratitude journaling, 
            and mindfulness tools. Designed its logo in Blender 4.3, with landscapes photographs I took during my time in New Zealand. Note: This app runs 100% locally on the user's device. No personal data is uploaded to external servers, so as to maintain total privacy.</p>
            <p>Starting neuroscience studies at UniSC (July 2025) to bridge research with education and well-being tools.</p>
            <WellnessApp />
            <h3>Music and VJ Loops</h3>
            <p>In my free time I make music with ableton live and 3d art with blender. Enjoy!</p>
            <VideoGrid />
          </section>
         
        </main>
        <footer className="footer">
          <p>Copyright © 2025 Cherie Tan. All Rights Reserved</p>
        </footer>
      </div>
    );
  }

  export default Home;
