import React from "react";

const ImageGrid = () => {
  return (
    <ul className='image-grid'>
      <li key="welcome">
        <img 
          src='https://dl.dropboxusercontent.com/s/fi/s4bgrxfxmdh9k192nn4yv/welcome-page.png?rlkey=iq2vldt92b3xzueuw67lf7ilb&st=p7lny4n0&dl=0' 
          alt='welcome page' 
        />
      </li>
      <li key="feeling">
        <img 
          src='https://dl.dropboxusercontent.com/s/fi/gzs9h8tnnxgeqi8iowyix/welcome-i-m-feeling-page.png?rlkey=6aybap51h17yzssjphjfnb3l4&st=az70u1uu&dl=0' 
          alt="I'm feeling page" 
        />
      </li>
      <li key="emotion-selector">
        <img 
          src='https://dl.dropboxusercontent.com/s/fi/19rbrukke912v3mnt9h2a/emotion-selector-page.png?rlkey=lzchvwy3y184xbgkjg6gm457f&st=qiahmlyu&dl=0'
          alt='emotion selector' 
        />
      </li>
      <li key="intensity-slider">
        <img 
          src='https://dl.dropboxusercontent.com/s/fi/vxzg6pwqol1x5assaddiw/emotion-intensity-slider-page.png?rlkey=hj7n1pwbia0fcms9c2dlqjx3s&st=ofx1ru7h&dl=0' 
          alt='emotion intensity slider' 
        />
      </li>
      <li key="reflectionprompt">
        <img 
          src='https://dl.dropboxusercontent.com/s/fi/qzesknzfi2g0472bbbzx5/reflection-app-response-page.png?rlkey=1xpny83zneffh5yqlh1xx0s43&st=b019609f&dl=0' 
          alt='prompt from app to reflect on emotion' 
        />
      </li>
      <li key="factors">
        <img 
          src='https://dl.dropboxusercontent.com/s/fi/1bdq6zdg89iwjlim718h3/factors-page.png?rlkey=snsqp11mzk898gkn1ujiiq39e&st=f6m6kxy4&dl=0' 
          alt='factors linked to emotion' 
        />
      </li>
      <li key="icon-picker">
        <img 
          src='https://dl.dropboxusercontent.com/s/fi/2dmpk6acnha9jueqr2xlv/icon-picker-page.png?rlkey=hsil3i3nodjhu5gj6fmjmbanx&st=rnd08adk&dl=0' 
          alt='icon picker functionality' 
        />
      </li>
      <li key="change">
        <img 
          src='https://dl.dropboxusercontent.com/s/fi/8wg47q3ejrevbzykvxk5k/change-my-thinking.png?rlkey=x7u0x337xdyp9248y79lqgchm&st=pidmux0c&dl=0' 
          alt='change my thinking' 
        />
      </li>
      <li key="gratitude">
        <img 
          src='https://dl.dropboxusercontent.com/s/fi/qttp6tpxmgztdwwgqshuw/gratitude-journaling-page.png?rlkey=05qfecgp7ga79xu4rhgsntlwj&st=xgf6gnmn&dl=0' 
          alt='gratitude journaling exercise' 
        />
      </li>
      <li key="add-factor">
        <img 
          src='https://dl.dropboxusercontent.com/s/fi/xyn53o92l194gbol9rtgg/add-new-factor-page.png?rlkey=i3jqrvkdtj0w509bqeb3va6uf&st=prf8c73i&dl=0' 
          alt='add new factor' 
        />
      </li>
      <li key="cbt2">
        <img 
          src='https://dl.dropboxusercontent.com/s/fi/nmohw7p2irejeagfuhid4/cbt.png?rlkey=zwvo0znli43zl7fjddyg7lmd0&st=gn4jm324&dl=0'
          alt='cognitive behavioral therapy exercise' 
        />
      </li>
    </ul>
  );
};

export default ImageGrid;